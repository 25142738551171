<template>
  <a-drawer width="40%" :label-col="4" :wrapper-col="14" :visible="open" :title="formTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" class="area-manager">
      <a-form-model-item label="开关" prop="onOff">
        <a-switch :disabled="readOnly" checked-children="开" un-checked-children="关" v-model="form.onOff" />
      </a-form-model-item>
      <a-form-model-item label="合法停车时长(分钟)" prop="antiShake">
        <a-input-number :disabled="readOnly" :precision="0" v-model="form.limitTime" :min="0" placeholder="请输入防抖延时" />
      </a-form-model-item>
      <a-form-model-item label="合法停车区域" prop="isDraw">
        <a-button @click="handleMapDraw">在地图中绘制</a-button>
      </a-form-model-item>
      <a-form-item label="车辆">
        <a-tree-select
          v-model="deviceIds"
          :disabled="readOnly"
          :replaceFields="replaceFields"
          show-search
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="模糊搜索，可搜索自编号或车牌号"
          allow-clear
          tree-default-expand-all
          :tree-data="vehicleTreeData"
          tree-checkable
          :show-checked-strategy="SHOW_PARENT"
          treeNodeFilterProp="title"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-model-item label="备注">
        <a-textarea :disabled="readOnly" v-model="form.remark" placeholder="请输入备注" :auto-size="{ minRows: 3 }" />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button v-if="!readOnly" type="primary" :loading="loading" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> {{ readOnly ? '返回' : '取消' }} </a-button>
        </a-space>
      </div>
    </a-form-model>
    <road-scope-map ref="roadScopeMapRef" :position="{ top: '4%', left: '52%' }" @ok="handleOk" />
  </a-drawer>
</template>

<script>
import debounce from 'lodash/debounce'
import { addWarningRule, updateWarningRule, getWarningRule } from '@/api/jt808/warningRule'
import { TreeSelect, Tree } from 'ant-design-vue'
import RoadScopeMap from '@/views/jt808/areaManager/modules/RoadScopeMap'
import { deviceTree } from '@/api/iot/device'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    ATreeSelect: TreeSelect,
    RoadScopeMap,
    ATree: Tree
  },
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      readOnly: false,
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        checkedKeys: []
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isDraw: [{ required: true, message: '合法停车区域不能为空', trigger: 'change' }]
      },
      deptOptions: [],
      roadScopeMapVisible: false,
      indeterminate: true,
      checkAll: false,
      colors: {},
      isOpenColor: false,
      samplingRules: [],
      vehicleTreeData: [],
      checkedKeys: [],
      vehicleLabelValueeArray: [],
      labelValueHashMap: new Map(),
      vehicleNameList: '',
      deviceIds: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    open(val) {}
  },
  mounted() {
    this.handleVehicleNoSearch()
  },
  methods: {
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const formData = new FormData()
      formData.append('deviceType', 1)
      formData.append('bindStatus', 'bind')
      if (value && !value.trim()) {
        formData.append('bindKey', value.trim())
      }
      deviceTree(formData).then((response) => {
        this.vehicleTreeData = response.data
      })
    },
    handleOk(arr) {
      if (arr.length > 0) {
        this.form.areas = arr
        this.form.isDraw = true
      } else {
        this.form.isDraw = undefined
      }
      this.$refs.form.clearValidate('isDraw')
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.readOnly = false
      this.loading = false
      this.formType = 1
      this.deviceIds = []

      this.form = {
        isDraw: undefined,
        antiShake: 60,
        areas: [],
        limitSpeed: 0,
        limitTime: 0,
        onOff: 0,
        remark: '',
        deviceIds: [],
        warnType: 4
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.form.limitTime = 15
      this.open = true
      this.formTitle = '新增停车超时设置'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids, type) {
      console.log('handleUpdate')
      this.reset()
      this.formType = 2
      this.readOnly = type === 'read'
      const id = row ? row.id : ids
      getWarningRule(id).then((response) => {
        this.form = response.data
        this.deviceIds = this.form.vehicles && this.form.vehicles.map((p) => 't-1-' + p.deviceId)
        if (this.form.areas && this.form.areas.length > 0) {
          this.form.isDraw = true
        }
        this.open = true
        this.formTitle = '修改停车超时设置'
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      const form = { ...this.form }
      const deviceIds = [...this.deviceIds]
      form.onOff = form.onOff ? 1 : 0
      form.deviceIds = deviceIds ? deviceIds.map((p) => (p.indexOf('t-1-') >= 0 ? p.substr(4) : p)) : []
      form.antiShake = form.antiShake * 1
      if (form.deviceIds.length === 0) {
        this.$warningEx('请选择车辆')
        return
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          if (form.id !== undefined && form.id !== null) {
            updateWarningRule(form)
              .then((response) => {
                this.loading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            addWarningRule(form)
              .then((response) => {
                this.loading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.loading = false
              })
          }
        } else {
          return false
        }
      })
    },
    handleMapDraw() {
      console.log('handleMapDraw')
      const form = {}
      form.geomArray = this.form.areas
      this.$refs.roadScopeMapRef.drawMap(form, true, this.readOnly)
    }
  }
}
</script>

<style lang="less">
.area-manager {
  .ant-form-item {
    display: flex;
    align-items: center;
    .ant-form-item-label {
      flex: 0 0 130px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .input-remark {
    margin-left: 10px;
  }
  .area-color {
    width: 25px;
    height: 25px;
    border: 1px solid black;
  }
  .bottom-control {
    border-top: 0;
    padding-top: 30px;
  }
}

.vehicle-tree {
  .ant-form-item-label > label::after {
    content: '' !important;
  }
}
</style>
